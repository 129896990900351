%quantitySelector {
  justify-content: center !important;
  align-content: center;
  flex-direction: column;

  .quantitySelector {
    &-container {
      display: flex;
      justify-content: center;
      .quantity-btn {
        margin-left: 0.5rem;
        height: 40px;
        width: 40px;
        border: none;
      }

      .quantity-content {
        margin-left: 0.5rem;
        height: 40px;
        width: 40px;
        border: none;
      }
    }
  }
}

%cartItem {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}
